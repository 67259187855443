<template>
  <div>
    <div class="heading">
      <button class="btn-lights">UmbrellaTree CoLab Benefits</button>
      <h1>Limitless Learning And Get More Possibilities</h1>
      <p>3+ thousand people are already learning on UmbrellaTree CoLab</p>
    </div>

    <div class="get-more">
      <div class="shadow-1">
        <div class="wrap">
          <img :src="`${aboutImages.personal}`" alt="">
          <div class="text">
            <h5>Personalized Learning</h5>
            <p>Students practice at their own pace, first filling in
              gaps in their understanding and then accelerating
              their learning.
            </p>
            <a href="#">Learn more</a>
          </div>
        </div>
      </div>
      <div class="shadow-1">
        <div class="wrap">
          <img :src="`${aboutImages.content}`" alt="">
          <div class="text">
            <h5>Trusted Content & Resources</h5>
            <p>Created by experts, UmbrellaTree CoLab library of
              trusted practice and lessons covers ML, AI, 
              Blockchain and more.
            </p>
            <a href="#">Learn more</a>
          </div>
        </div>
      </div>

      <div class="shadow-1">
        <div class="wrap">
          <img :src="`${aboutImages.expert}`" alt="">
          <div class="text">
            <h5>Learn From Experts</h5>
            <p>Access to industry-experts anytime and anywhere
              and 3 months mentorship program.
            </p>
            <a href="#">Learn more</a>
          </div>
        </div>
      </div>
      <div class="shadow-1">
        <div class="wrap">
          <img :src="`${aboutImages.pace}`" alt="">
          <div class="text">
            <h5>Learn At Your Own Pace</h5>
            <p>Students practice at their own pace, first filling in
              gaps in their understanding and then accelerating
              their learning.
            </p>
            <a href="#">Learn more</a>
          </div>
        </div>
      </div>

      <div class="shadow-1">
        <div class="wrap">
          <img :src="`${aboutImages.cert}`" alt="">
          <div class="text">
            <h5>Earn A Certificate</h5>
            <p>You can learn from every level of expertise from
              beginners, intermediate, and experts level and earn
              yourself an industry recognised certification.
            </p>
            <a href="#">Learn more</a>
          </div>
        </div>
      </div>
      <div class="shadow-1">
        <div class="wrap">
          <img :src="`${aboutImages.empower}`" alt="">
          <div class="text">
            <h5>Career Empowerment</h5>
            <p>Lifetime access to resources, instructors and also
              business development for entrepreneurs.
            </p>
            <a href="#">Learn more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import aboutImages from "../../assets/images/about";
export default {
  data:() => {
    return{
      aboutImages,
    }
  },
}
</script>
<style scoped>
  .heading{
    text-align: center;
  }
  .heading .btn-lights{
    color:var(--primary);
    background: #FDE2D7;
    border:none;
    border-radius: 4px;
    width:260px;
    height:50px;
    cursor:pointer;
    font-size: 16px;
    font-weight: 700;
    margin:20px auto;
  }
  @media screen and (max-width:768px){
    .heading h1{
      font-size: 16px;
    }
    .heading p{
      font-size: 12px;
    }
  }

  .get-more{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px,1fr));
    gap:30px;
    margin:60px auto;
  }
  .get-more .shadow-1 .wrap{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap:15px;
    place-items: center;
    padding: 20px;
    font-family: Mulish;
  }
  .get-more .shadow-1 .wrap .text h5{
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
  }
  .get-more .shadow-1 .wrap .text p{
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }
  .get-more .shadow-1 .wrap .text a{
    color:var(--primary);
  }
  @media screen and (max-width:768px){
    .get-more{
      grid-template-columns: repeat(auto-fit, minmax(320px,1fr));
      gap:10px;
      margin:0 3px;
    }
    .get-more .shadow-1 .wrap{
      padding: 5px;
    }
    .get-more .shadow-1 .wrap .text h5{
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
    }
    .get-more .shadow-1 .wrap .text p{
      font-weight: 400;
      font-size: 12px;
      line-height: 23px;
    }
  }
</style>