<template>
  <section>
    <no-course
      :img="require('@/assets/images/dashboard/enrolled-courses/completed-courses.svg')"
      msg="There is no completed course"
    />
  </section>
</template>

<script>
import NoCourse from "./components/noCourse.vue";
export default {
  components: {
    NoCourse,
  },
};
</script>