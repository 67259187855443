<template>
  <section class="reset-password">
    <h4>Reset Password</h4>
    <form>
      <div class="form">
        <div class="full-width">
          <label for="password">Email</label>
          <input type="password" name="password" />
        </div>
        <div>
          <label for="new-pass">New Password</label>
          <input type="password" name="password" />
        </div>
        <div>
          <label for="confirm-pass">Confirm Password</label>
          <input type="password" name="password" />
        </div>
      </div>
      <input type="submit" value="Reset Password" class="reset" />
    </form>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
h4 {
  font-size: 16px;
  font-weight: 600;
}

.form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 25px 0;
  padding: 0px !important;
}

.form input {
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgba(34, 10, 0, 0.5);
  padding: 8px 15px;
  height: 45px;
  outline: none;
  color: rgba(34, 10, 0, 0.8);
  font-size: 14px;
}

.reset {
  cursor: pointer;
  width: fit-content;
  background-color: turquoise;
}

.form label {
  display: block;
  color: rgba(34, 10, 0, 0.8);
  font-size: 14px;
  margin-bottom: 10px;
}

.full-width {
  grid-column: 1 / 3;
}

.reset {
  background: #ff4c00 !important;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  padding: 8px 30px;
  height: 45px;
  font-size: 14px;
}
</style>