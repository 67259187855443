<template>
  <div>
    <div class="cover-photo-wrapper">
      <img src="@/assets/images/dashboard/cover-image.png" alt="" />
      <div class="delete-icon">
        <img src="@/assets/icons/dashboard/delete.svg" alt="" />
      </div>
      <div class="upload-button">
        <button class="upload">Upload Cover Photo</button>
      </div>
      <div class="avatar">
        <div>
          <img class="cam" src="@/assets/images/dashboard/avatar.png" alt="" />
          <img class="pic" src="@/assets/icons/dashboard/camera.svg" alt="" />
        </div>
      </div>
      <p>
        Profile Photo size: 100 x 100 pixels, Cover photo size: 700 400 pixels
      </p>
    </div>

    <div class="profile-form">
      <form>
        <div class="form">
          <div>
            <label>First Name</label>
            <input type="text" />
          </div>
          <div>
            <label>Last Name</label>
            <input type="text" />
          </div>
          <div>
            <label>User Name</label>
            <input type="text" />
          </div>
          <div>
            <label>Phone Number</label>
            <input type="tel" />
          </div>
          <div class="full-width">
            <label>Bio</label>
            <textarea rows="4" cols="50">
e.g: I’m a creative designer with knowledge of HTML and CSS.</textarea
            >
          </div>
          <div>
            <label>Display name publicly as</label>
            <select>
              <option value="">Deborah Wright</option>
            </select>
            <p>
              The display name is shown in all public fields, and also the name
              that will be printed on your certificate
            </p>
          </div>
          <div class="full-width">
            <label>Website/Portfolio URL</label>
            <input type="text" placeholder="https://example.com/" />
          </div>
          <div class="full-width">
            <label>Github URL</label>
            <input type="text" placeholder="https://github.com/username" />
          </div>
          <div class="full-width">
            <label>Facebook URL</label>
            <input type="text" placeholder="https://facebook.com/username" />
          </div>
          <div class="full-width">
            <label>LinkedIn URL</label>
            <input type="text" placeholder="https://linkedin.com/username" />
          </div>
          <div class="full-width">
            <label>Twitter URL</label>
            <input type="text" placeholder="https://twitter.com/username" />
          </div>
        </div>
        <input type="submit" value="Update Profile" class="update-profile" />
      </form>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cover-photo-wrapper {
  position: relative;
}

.cover-photo-wrapper p {
  font-size: 12px;
  position: absolute;
  bottom: -25px;
  right: 80px;
}

.avatar {
  position: absolute;
  bottom: -80px;
  left: 16px;
}

.avatar img:nth-child(1) {
  width: 150px;
}

.avatar img:nth-child(2) {
  width: 20px;
  position: absolute;
  cursor: pointer;
  bottom: 45px;
  left: 65px;
}

.upload-button {
  position: absolute;
  bottom: 20px;
  right: 80px;
}

.delete-icon img {
  width: 35px;
  cursor: pointer;
}

.delete-icon {
  position: absolute;
  top: 20px;
  right: 80px;
}

.upload {
  background: #ff4c00 !important;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  padding: 8px 30px;
  height: 45px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border: none;
}

.form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 70px 0;
  padding: 0px !important;
}

.form p {
  text-align: left;
  color: rgba(34, 10, 0, 0.8);
  font-size: 12px;
}

.form input,
.form select {
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgba(34, 10, 0, 0.5);
  padding: 8px 15px;
  height: 45px;
  outline: none;
  color: rgba(34, 10, 0, 0.8);
  font-size: 14px;
}

.form textarea {
  border-radius: 4px;
  width: 100%;
  color: rgba(34, 10, 0, 0.8);
  padding: 8px 15px;
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(34, 10, 0, 0.5);
}

.update-profile {
  cursor: pointer;
  width: fit-content;
  background: #ff4c00 !important;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  padding: 8px 30px;
  height: 45px;
  font-size: 14px;
}

.form label {
  display: block;
  color: rgba(34, 10, 0, 0.8);
  font-size: 14px;
  margin-bottom: 10px;
}

.full-width {
  grid-column: 1 / 3;
}
</style>