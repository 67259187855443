<template>
  <section>
    <no-course
      :img="
        require('@/assets/images/dashboard/enrolled-courses/active-courses.svg')
      "
      msg="You do not have an active course at the moment"
    />
  </section>
</template>

<script>
import NoCourse from "./components/noCourse.vue";
export default {
  components: {
    NoCourse,
  },
};
</script>