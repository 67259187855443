<template>
  <h2 class="dashboard-title">Settings</h2>
  <div class="settings-nav">
    <button
      @click="currentTab = 'profile'"
      :class="{ activeTab: currentTab === 'profile' }"
    >
      Profile
    </button>
    <button
      @click="currentTab = 'reset-password'"
      :class="{ activeTab: currentTab === 'reset-password' }"
    >
      Reset Password
    </button>
  </div>
  <keep-alive class="keep-alive">
    <component :is="currentTab"></component>
  </keep-alive>
</template>

<script>
import ResetPassword from "./settings/resetPassword.vue";
import Profile from "./settings/profileSettings.vue";
export default {
  components: {
    ResetPassword,
    Profile,
  },

  data() {
    return {
      currentTab: "profile",
    };
  },
};
</script>

<style scoped>
.activeTab {
  color: #001fc1;
  border-bottom: 1px solid #001fc1;
}

.dashboard-title {
  font-size: 24px;
  font-weight: 600;
  color: rgba(34, 10, 0, 1);
  margin-bottom: 40px;
}

.settings-nav {
  border-bottom: 1px solid rgba(34, 10, 0, 0.2);
}

button {
  padding: 8px 15px;
  outline: none;
  border: none;
  font-size: 14px;
  background-color: transparent;
  margin-right: 25px;
  cursor: pointer;
  color: rgba(34, 10, 0, 0.7);
}

button:last-child {
  margin: 0;
}

.keep-alive {
  padding: 40px 0;
  margin: 0px;
  max-width: 90%;
}
</style>

