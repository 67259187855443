<template>
  <div class="dashboard-layout">
    <nav class="navbar">
      <div class="logo-box">
        <img
          src="@/assets/images/dashboard/logo.png"
          class="logo"
          alt="utcolab logo"
        />
      </div>
      <div class="nav-end">
        <div class="nav-end__left">
          <img
            src="@/assets/icons/dashboard/menu-bar.svg"
            class="menu"
            alt=""
            @click="toggleSidebar"
          />
          <div class="search-wrapper">
            <input
              class="search-box"
              type="search"
              placeholder="What would you like to learn?"
            />
            <div class="seearch-icon-box">
              <img
                src="@/assets/icons/dashboard/search.svg"
                class="search-icon"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="nav-end__right">
          <a class="notifications" href="#">
            <img
              src="@/assets/icons/dashboard/notification-bell.svg"
              alt="notifications icon"
            />
            <span class="badge"></span>
          </a>
          <div class="nav-dropdown" @click="toggleDropDownMenu">
            <div class="initials">
              <span>{{ initials }}</span>
            </div>
            <div class="user">
              <div class="username">{{ fullname }}</div>
            </div>
            <div>
              <img
                src="@/assets/icons/dashboard/down-arrow.svg"
                alt="downArrow"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="sidebar" :class="{ sidebarCollapse: !sidebarIsOpen }">
      <div class="sidebar-inner">
        <ul>
          <li class="sidebar-link">
            <router-link
              to="/dashboard"
              class="link-wrapper"
              :class="{ active: isActive }"
            >
              <span class="icon">
                <img src="@/assets/icons/dashboard/dashboard.svg" alt="" />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Dashboard</span>
              </transition>
            </router-link>
          </li>
          <li class="sidebar-link">
            <router-link
              to="/enrolled-courses"
              class="link-wrapper"
              :class="{ active: isActive }"
            >
              <span class="icon">
                <img src="@/assets/icons/dashboard/enrolled.svg" alt="" />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Enrolled courses</span>
              </transition>
            </router-link>
          </li>
          <li class="sidebar-link">
            <router-link
              to="/wishlist"
              class="link-wrapper"
              :class="{ active: isActive }"
            >
              <span class="icon">
                <img src="@/assets/icons/dashboard/wishlist.svg" alt="" />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Wishlist</span>
              </transition>
            </router-link>
          </li>
          <li class="sidebar-link">
            <router-link
              to="/quiz-attempts"
              class="link-wrapper"
              :class="{ active: isActive }"
            >
              <span class="icon">
                <img src="@/assets/icons/dashboard/quiz-attempts.svg" alt="" />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Quiz attempts</span>
              </transition>
            </router-link>
          </li>
          <li class="sidebar-link">
            <router-link
              to="/purchase-history"
              class="link-wrapper"
              :class="{ active: isActive }"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/dashboard/purchase-history.svg"
                  alt=""
                />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Purchase history</span>
              </transition>
            </router-link>
          </li>
          <li class="sidebar-link">
            <router-link
              to="/profile"
              class="link-wrapper"
              :class="{ active: isActive }"
            >
              <span class="icon">
                <img src="@/assets/icons/dashboard/profile.svg" alt="" />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Profile</span>
              </transition>
            </router-link>
          </li>
          <li class="sidebar-link">
            <router-link
              to="/settings"
              class="link-wrapper"
              :class="{ active: isActive }"
            >
              <span class="icon">
                <img src="@/assets/icons/dashboard/settings.svg" alt="" />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Settings</span>
              </transition>
            </router-link>
          </li>
          <li class="sidebar-link">
            <a href="" class="link-wrapper" :class="{ active: isActive }">
              <span class="icon">
                <img src="@/assets/icons/dashboard/logout.svg" alt="" />
              </span>
              <transition name="fade">
                <span class="text" v-if="sidebarIsOpen">Logout</span>
              </transition>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view" :class="{ wideMain: !sidebarIsOpen }">
      <div class="main-view-wrapper">
        <div class="router-view"><router-view></router-view></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",

  data() {
    return {
      sidebarIsOpen: true,

      user: {
        firstname: "Deborah",
        lastname: "Wright",
      },

      sidebar: [
        {
          heading: "Dashboard",
          icon: require("@/assets/icons/dashboard/dashboard.svg"),
          path: "",
        },
        {
          heading: "Enrolled courses",
          icon: require("@/assets/icons/dashboard/enrolled.svg"),
          path: "/enrolled-courses",
        },
        {
          heading: "Wishlist",
          icon: require("@/assets/icons/dashboard/wishlist.svg"),
          path: "/wishlist",
        },
        {
          heading: "Quiz attempts",
          icon: require("@/assets/icons/dashboard/quiz-attempts.svg"),
          path: "/quiz-attempts",
        },
        {
          heading: "Purchase history",
          icon: require("@/assets/icons/dashboard/purchase-history.svg"),
          path: "/purchase-history",
        },
        {
          heading: "Profile",
          icon: require("@/assets/icons/dashboard/profile.svg"),
          path: "/profile",
        },
        {
          heading: "Settings",
          icon: require("@/assets/icons/dashboard/settings.svg"),
          path: "/settings",
        },
        {
          heading: "Logout",
          icon: require("@/assets/icons/dashboard/logout.svg"),
        },
      ],
    };
  },

  methods: {
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
  },

  computed: {
    initials() {
      return (
        this.user.firstname.charAt(0) + this.user.lastname.charAt(0)
      ).toUpperCase();
    },

    fullname() {
      return this.user.firstname + " " + this.user.lastname;
    },
  },
};
</script>

<style scoped>
.dashboard-layout {
  background-color: #f9f9f9;
}

img {
  width: 100%;
}

.navbar {
  height: 75px;
  display: flex;
  align-items: center;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  padding: 0 40px;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.3);
}

.nav-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}

.nav-end .nav-end__left,
.nav-end .nav-end__right {
  display: flex;
  align-items: center;
}

.nav-end__left {
  padding-left: 25px;
  column-gap: 35px;
}

.nav-end__right {
  column-gap: 40px;
}

.nav-end .nav-end__left .menu {
  cursor: pointer;
}

.navbar .logo {
  width: 120px;
}

.logo-box {
  width: 300px;
}

.nav-end .nav-end__left .search-wrapper {
  display: inline-flex;
  flex: 1 1 350px;
  position: relative;
  border: none;
  overflow: hidden;
}

.navbar img.menu {
  width: 33px;
}

.search-wrapper input {
  outline: none;
  width: 293px;
  border-radius: 2px 0 0 2px;
  border: 1px solid rgba(255, 76, 0, 1);
}

.search-icon {
  padding: 0.5rem 1rem 0.5rem 1rem;
  height: 100%;
  border: 1px 0 1px 1px solid rgba(255, 76, 0, 1);
  border-radius: 0 2px 2px 0;
  background-color: rgba(255, 76, 0, 1);
}

.search-box {
  border: 0;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  flex: 1;
}

.notifications {
  position: relative;
  margin-right: 35px;
}

.notifications .badge {
  position: absolute;
  top: -2px;
  right: -4px;
  padding: 4px;
  border-radius: 50%;
  background: red;
}

.nav-dropdown {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.nav-dropdown .initials {
  background: #9090901c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  /* padding: 20px; */
}

.nav-dropdown div.user {
  font-size: 14px;
}

.nav-dropdown div.username {
  font-weight: 500;
}

.nav-dropdown:hover {
  cursor: pointer;
}

.nav-dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  margin-top: 30px;
  margin-left: -170px;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.3);
}

.dropdown-menu {
  display: none;
}

.dropdown-menu {
  width: 180px;
  overflow: hidden;
  padding: 20px 0;
  z-index: 1;
  background: #fff;
  display: block;
  position: absolute;
  margin-top: 30px;
  margin-left: -170px;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.3);
}

.dropdown-menu ul li a {
  display: block;
  padding: 5px 15px;
  color: rgba(34, 10, 0, 0.7);
  transition: all 0.2s ease;
  text-decoration: none;
}

.dropdown-menu ul li a .icon {
  vertical-align: middle;
  transition: all 0.2s ease;
}

.dropdown-menu ul li a:hover .icon img {
  fill: #ff4c00;
}

.dropdown-menu ul li a .text {
  margin-left: 15px;
}

.dropdown-menu ul li a:hover {
  color: #ff4c00;
}

/* SIDEBAR */

.sidebar {
  /* height: 100%;
  width: 300px;
  position: fixed;
  top: 75px;
  left: 0;
  padding-top: 40px;
  padding-bottom: 140px;
  z-index: 1;
  background: #fff; */
  /*Hide scrollbar for IE and Edge */
  /* overflow-y: scroll;
  -ms-overflow-style: none; */
  /*Hide scrollbar for Firefox*/
  /* scrollbar-width: none;  */
  padding: 40px 0;
  /* color: white; */
  background: white;
  width: 300px;
  float: left;
  position: fixed;
  top: 75px;
  left: 0;
  bottom: 0;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
}

/* Hide scrollbar for Chrome */
/* .sidebar::-webkit-scrollbar {
  display: none; 
} */

.sidebarCollapse {
  width: 102px;
}

.sidebar img {
  width: 22px;
  flex-shrink: 0;
  margin-right: 20px;
  display: inline-block;
}

/* .sidebar-link span.icon im */

.link-wrapper {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  position: relative;
  user-select: none;
}

.link-wrapper.active {
  background-color: #ff4c00;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.link-wrapper:hover {
  background-color: #ff4c00;
}

.link-wrapper:hover > span.text {
  color: white;
}

/* .router-link-active {
  background: purple;
} */

.sidebar-link span.text {
  display: inline-block;
}

/* .noSideLink {
  display: none;
} */

.sidebar ul li a {
  display: block;
  padding: 12px 40px;
  margin-right: 25px;
  transition: all 0.2s;
  color: rgba(34, 10, 0, 0.7);
  transition: all 0.2s ease;
  text-decoration: none;
}

.sidebar ul li a .icon {
  vertical-align: middle;
  transition: all 0.2s ease;
}

.main-view {
  margin-top: 75px;
  margin-left: 300px;
  background-color: #fff;
  height: 100%;
  max-width: calc(100% - 300px);
  overflow: hidden;
}

.main-view .main-view-wrapper {
  margin: 25px 40px 0 0;
  background-color: #f9f9f9;
  padding: 30px;
}

.wideMain {
  max-width: calc(100% - 102px);
  margin-left: 102px;
}

.router-view {
  width: 80%;
}
</style>