<template>
  <div class="no-course-container">
    <p class="no-course-text">{{ msg }}</p>
  </div>
  <div class="no-courses-img-container">
    <img :src="img" :alt="msg" class="no-courses-img" />
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    msg: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.no-course-container {
  border: 1px solid rgba(34, 10, 0, 0.5);
  padding: 28.5px 44.5px;
  border-radius: 4px;
  margin-bottom: 52.5px;
}

.no-course-text {
  color: rgba(34, 10, 0, 0.7);
}

.no-courses-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-courses-img {
  width: 280px;
  /* display: block; */
}
</style>