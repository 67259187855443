import playBtn from "./play-btn.svg";
import ai from "./ai.svg";
import cloud from "./cloud.svg";
import vr from "./vr.svg";
import ml from "./ml.svg";


const homeIcons = {
  playBtn,
  ai,
  cloud,
  vr,
  ml,
}
export default homeIcons;