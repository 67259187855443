<template>
  <section>
    <div class="banner">
      <div class="banner-img">
        <img :src="`${homeImages.indexWelcome}`" alt="" />
      </div>
      <div class="banner1-text">
        <h3>Welcome to UmbrellaTree CoLab</h3>
        <p class="txt-b1">
          We are a European-African start-up aiming to empower African women
          through emerging technologies. We offer training, career advancement &
          entrepreneurship programmes for women in Africa interested in emerging
          technologies and taking their career in tech to the next level!
        </p>
        <span class="read-more-container">
          <router-link to="/about" class="readmore">Read more</router-link>
        </span>
      </div>
    </div>
  </section>
</template>
<script>
import homeImages from "../../assets/images/home";
export default {
  data: () => {
    return {
      homeImages,
    };
  },
};
</script>
<style scoped>
.banner {
  display: grid;
  grid-template-areas: "image text";
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 100px;
  width: 100%;
  align-items: center;
  margin: 100px auto 37px auto;
}
.banner .banner-img {
  width: 420px;
  grid-area: image;
}

@media (max-width: 768px) {
  .banner .banner-img {
    width: 100%;
    margin: 0 auto;
  }
}

.banner .banner1-text {
  grid-area: text;
}
.banner .banner1-text h3 {
  font-size: 28px;
  line-height: 45px;
  font-weight: 700;
  margin-bottom: 35px;
}
.banner .banner1-text p {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 35px;
}
.banner .banner1-text .read-more-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  width: 50%;
}
.banner .banner1-text .read-more-container .readmore {
  font-size: 16px;
  color: var(--primary);
  cursor: pointer;
}
.banner .banner1-text .read-more-container img {
  width: 20%;
  cursor: pointer;
  margin-top: 5%;
}
@media screen and (max-width: 768px) {
  .banner {
    grid-template-areas:
      "text"
      "image";
    gap: 15px;
    padding: 10px;
    margin: 30px auto 37px auto;
  }
  .banner .banner-img {
    width: 80%;
  }
  .banner .banner1-text .read-more-container {
    width: 180px;
  }
  .banner .banner1-text p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 19px;
  }
  .banner .banner1-text .read-more-container .readmore {
    font-size: 16px;
  }
  .banner .banner1-text .read-more-container img {
    width: 30%;
    margin-top: 6%;
  }
}
</style>