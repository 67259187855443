<template>
  <section>
    <div class="mt-5">
      <h3 class="title-h1">How to get started?</h3>
      <div class="flow">
        <div class="d-card shadow-1">
          <img :src="`${homeImages.search}`" alt="" />
          <div class="card-item">
            <h5>Find Your Course</h5>
            <p>
              Check through our platform and choose the course of your choice
              and get started.
            </p>
          </div>
        </div>
        <img class="direction" :src="`${homeImages.arrowFill}`" alt="" />
        <div class="d-card shadow-1">
          <img :src="`${homeImages.calendar}`" alt="" />
          <div class="card-item">
            <h5>Sign Up</h5>
            <p>
              When you’ve found the course, sign up and start learning. We have
              created videos, learning materials for you to get ready for the
              live session with emerging technologies experts.
            </p>
          </div>
        </div>
        <img class="direction" :src="`${homeImages.arrowFill}`" alt="" />
        <div class="d-card shadow-1">
          <img :src="`${homeImages.badge}`" alt="" />
          <div class="card-item">
            <h5>Get your certificate</h5>
            <p>
              After completing the whole course you will be issued a
              certificate.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import homeImages from "../../assets/images/home";
export default {
  data: () => {
    return {
      homeImages,
    };
  },
};
</script>
<style scoped>
.title-h1 {
  margin-top: 100px;
  min-width: 200px;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 10px;
  margin: 0 auto;
  line-height: 2em;
}

.title-h1::after {
  content: "";
  display: grid;
  height: 8px;
  margin-top: 20px;
  border-radius: 50px;
  width: 125px;

  margin: 0 auto;
  background-color: var(--primary);
  place-items: center;
}

.flow {
  display: flex;
  gap: 30px;
  place-items: center;
  justify-content: center;
  margin-top: 50px;
}

.flow .direction {
  width: 40px;
}

.flow .d-card {
  width: 300px;
  min-height: 260px;
  padding: 30px 20px;
}

.flow .d-card img {
  width: 40px;
}

.flow .d-card .card-item h5 {
  margin: 30px 0 15px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.flow .d-card .card-item p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (max-width: 768px) {
  .flow {
    flex-direction: column;
    gap: 10px;
  }

  .flow .direction {
    width: 40px;
    transform: rotate(90deg);
    margin: 15px 0;
  }

  .title-h1 {
    text-decoration: none;
  }
}
</style>