<template>
  <section>
    <Hero />
    <Welcome />
    <Mission />
    <GetMore />
    <Category />
    <programmes />
    <instructors />
    <testimonials />
    <team />
  </section>

</template>

<script>
import Hero from "./about/hero.vue";
import Welcome from "./about/welcome.vue";
import Mission from "./about/mission.vue";
import GetMore from "./about/getMore.vue";
import Category from "./about/category.vue";
import testimonials from "./about/testimonials.vue";
import instructors from "./about/instructors.vue";
import programmes from "./about/programmes.vue";
import team from "./about/team.vue";
export default {
  components:{
    Hero,
    Welcome,
    Mission,
    GetMore,
    Category,
    testimonials,
    instructors,
    programmes,
    team,
  },
}
</script>

<style>
</style>