<template>
  <div class="knowledge-wrapper">
    <div class="knowledge-main-wrapper">
      <div class="top-images">
        <img src="@/assets/images/Ellipse-294.svg" alt="" />
        <img src="@/assets/images/Ellipse-298.svg" alt="" />
      </div>
      <div class="knowledge-wrapper-content">
        <div class="knowledge-wrapper-left">
          <p class="join-mentor">Join us as a Mentor!</p>
          <h3>
            Do you have expertize in emerging <br />
            technologies and empowering women?
          </h3>
          <p>Create an interactive course together with UmbrellaTree CoLab</p>
        </div>
        <div class="knowledge-wrapper__right">
          <button class="become-a-mentor">Become a Mentor</button>
        </div>
      </div>
      <div class="bottom-images">
        <img src="@/assets/images/Ellipse-295.svg" alt="" />
        <img src="@/assets/images/Ellipse-299.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.knowledge-wrapper {
  padding: 30px 0;
  background: white;
  margin: 30px 0;
}

@media (max-width: 768px) {
  .knowledge-wrapper {
    padding: 0;
    margin: 0 auto;
  }
}

.knowledge-main-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  max-width: 1050px;
  margin: 0 auto;
}

.top-images,
.bottom-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-images {
  width: 85%;
  margin: auto;
}

img {
  width: 50px;
}

.knowledge-wrapper-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97.5%;
}

p.join-mentor {
  padding: 5px 20px;
  background: rgba(255, 76, 0, 0.2);
  color: #ff4c00;
  display: inline-block;
  border-radius: 4px;
}

.knowledge-wrapper-content h3 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0 10px 0;
}

.knowledge-wrapper-content h3 > p {
  font-weight: 500;
}

.become-a-mentor {
  background: #ff4c00;
  border-radius: 4px;
  padding: 15px 70px;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .divider {
    display: none;
  }

  .top-images img:nth-child(2),
  .bottom-images {
    display: none;
  }

  .knowledge-wrapper-content {
    display: block;
    width: inherit;
  }

  .knowledge-wrapper {
    padding: 20px 30px 65px 20px;
  }

  p.join-mentor {
    display: none;
  }

  .knowledge-wrapper-content h3 {
    font-size: 24;
  }

  .knowledge-wrapper-content p {
    margin: 20px 0 35px 0;
  }
}
</style>