<template>
  <h2 class="profile-title">Profile</h2>
  <div class="cover-photo-wrapper">
    <img src="@/assets/images/dashboard/cover-image.png" alt="" />
    <div class="avatar">
      <div>
        <img class="cam" src="@/assets/images/dashboard/avatar.png" alt="" />
      </div>
    </div>
  </div>
  <div class="profile-form">
    <form>
      <div class="form">
        <div>
          <label>First Name</label>
          <input type="text" />
        </div>
        <div>
          <label>Last Name</label>
          <input type="text" />
        </div>
        <div class="full-width">
          <label>Email</label>
          <input type="email" />
        </div>
        <div class="full-width">
          <label>Contact</label>
          <input type="tel" />
        </div>
        <div class="full-width">
          <label>Date of Birth</label>
          <input type="date" />
        </div>
        <div>
          <label>City/State</label>
          <input type="text" />
        </div>
        <div>
          <label>Zip Code</label>
          <input type="text" />
        </div>
      </div>
      <div class="profile-buttons">
        <button class="back-home">Back to Home</button>
        <input type="submit" value="Update Profile" class="update-profile" />
      </div>
    </form>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.profile-title {
  font-size: 24px;
  font-weight: 600;
  color: rgba(34, 10, 0, 1);
  margin-bottom: 40px;
}

.cover-photo-wrapper {
  position: relative;
}

.cover-photo-wrapper p {
  font-size: 12px;
  position: absolute;
  bottom: -25px;
  right: 80px;
}

.avatar {
  position: absolute;
  bottom: -80px;
  left: 16px;
}

.avatar img:nth-child(1) {
  width: 150px;
}

.form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 70px 0;
  padding: 0px !important;
}

.form p {
  text-align: left;
  color: rgba(34, 10, 0, 0.8);
  font-size: 12px;
}

.form input,
.form select {
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgba(34, 10, 0, 0.5);
  padding: 8px 15px;
  height: 45px;
  outline: none;
  color: rgba(34, 10, 0, 0.8);
  font-size: 14px;
}

.form textarea {
  border-radius: 4px;
  width: 100%;
  color: rgba(34, 10, 0, 0.8);
  padding: 8px 15px;
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(34, 10, 0, 0.5);
}

.update-profile {
  cursor: pointer;
  width: fit-content;
  background: #ff4c00 !important;
  color: white;
  border-radius: 4px;
  border: 1 solid #ff4c00;
  font-weight: bold;
  padding: 8px 30px;
  height: 45px;
  font-size: 14px;
}

.back-home {
  background-color: transparent;
  cursor: pointer;
  width: fit-content;
  color: #ff4c00;
  border-radius: 4px;
  /* border: 1 solid #ff4c00; */
  font-weight: bold;
  border: 1px solid #ff4c00;
  padding: 8px 30px;
  height: 45px;
  font-size: 14px;
}

.form label {
  display: block;
  color: rgba(34, 10, 0, 0.8);
  font-size: 14px;
  margin-bottom: 10px;
}

.profile-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: right;
}

.full-width {
  grid-column: 1 / 3;
}
</style>

