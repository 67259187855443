<template>
  <div>
    <hero />
    <welcome />
    <world_class />
    <how_it_works />
    <course_categories />
    <what_we_offer />
    <knowledge />
    <why />
    <testimonials />
    <top_courses />
  </div>
</template>

<script>
import hero from "./home/hero.vue";
import welcome from "./home/welcome.vue";
import world_class from "./home/world-class.vue";
import how_it_works from "./home/how-it-works.vue";
import course_categories from "./home/course-categories.vue";
import what_we_offer from "./home/what-we-offer.vue";
import knowledge from "./home/knowledge.vue";
import why from "./home/why.vue";
import testimonials from "./home/testimonials.vue";
import recent_blogs from "./home/recent-blogs.vue";
import top_courses from "./home/top-courses.vue";

export default {
  components: {
    hero,
    welcome,
    world_class,
    how_it_works,
    course_categories,
    what_we_offer,
    knowledge,
    why,
    testimonials,
    recent_blogs,
    top_courses,
  },
  data: function () {
    return {};
  },
};
</script>

<style>
</style>