<template>
  <div class="about-hero-wrap">
    <div class="hero-img">
      <img :src="`${aboutImages.hero}`" />
    </div>
    <div class="about">
      <h4>ABOUT UMBRELLATREE COLAB</h4>
      <h1>Build Your Skills With Industry-<br/>
        Experts And The Best Course<br/>
        Curriculum.
      </h1>
      <div class="play">
        <img :src="`${aboutIcons.play}`" alt="">
        <p>VIDEO: Who we are, What we do <br/>
          and Why we do it.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import aboutIcons from "../../assets/icons/about";
import aboutImages from "../../assets/images/about";

export default {
  data: () => {
    return{
      aboutIcons,
      aboutImages,
    }
  },
}
</script>
<style scoped>
  .about-hero-wrap{
    margin:49px auto 100px auto;
    position:relative;
  }
  .about-hero-wrap .about{
    position: absolute;
    top:20%;
    left:40px;
   
  }
  .about-hero-wrap .about h4{
    font-size: 16px;
    line-height:28px;
    font-weight: 700;
    color:#fff;
  }
  .about-hero-wrap .about h1{
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    margin: 11% 0;
    color:#fff;
  }
  .about-hero-wrap .about .play{
    display: flex;
    align-items: center;
    gap:10px;
  }
  .about-hero-wrap .about .play img{
    width: 15%;
  }
  .about-hero-wrap .about .play p{
    color:#fff;
  }
  @media screen and (max-width:450px){
    .about-hero-wrap .about{
      position: absolute;
      top:5%;
      left:10px;
    }
    .about-hero-wrap .about h4{
      font-size: 12px;
      line-height:20px;
      font-weight: 500;
    }
    .about-hero-wrap .about h1{
      font-size: 16px !important;
      line-height: 16px !important;
      font-weight: 600;
      margin: 4% 0 !important;
    }
    .about-hero-wrap .about .play{
      gap:10px;
    }
    .about-hero-wrap .about .play img{
      width: 9%;
    }
    .about-hero-wrap .about .play p{
      color:#fff;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
  @media screen and (max-width:768px){
    .about-hero-wrap{
      margin:39px auto 30px auto;
    }
    .about-hero-wrap .about{
      top:10%;
      left:15px;
    }
    .about-hero-wrap .about h4{
      font-size: 14px;
      line-height:20px;
      font-weight: 500;
     
    }
    .about-hero-wrap .about h1{
      font-size: 22px;
      line-height: 26px;
      font-weight: 600;
      margin: 15% 0;
    }
    .about-hero-wrap .about .play img{
      width: 10%;
    }
  }

</style>
