<template>
  <div class="instructors">
    <div class="instructors-label">Instructors</div>
    <h3>Meet Our Instructors</h3>
    <p>
      Our expert team is made up of creatives with technical knowledge,
      strategists who think outside the box, and developers who push
      innovations.
    </p>
    <div class="instructor-wrapper">
      <div
        v-for="(instructor, index) in instructors"
        :key="index"
        class="instructor-main-wrapper"
      >
        <div class="instructor-box">
          <img class="instructor-image" :src="instructor.avatar" alt="" />
          <div class="instructor-info">
            <span class="instructor-name">{{ instructor.name }}</span>
            <span class="instructor-role">{{ instructor.role }}</span>
            <span class="instructor-rating"
              >{{ instructor.rating }} (ratings)</span
            >
            <div>
              <span class="instructor-students"
                >{{ instructor.students }}+ Students</span
              ><span class="instructor-courses"
                >{{ instructor.courses }} Courses</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      instructors: [
        {
          name: "Jessica Trump",
          avatar: require("@/assets/images/instructor1.png"),
          role: "Blockchain Instructor",
          courses: 22,
          rating: 4.5,
          students: 3000,
        },
        {
          name: "Marvelous Akporowho",
          avatar: require("@/assets/images/instructor2.png"),
          role: "Machine Learning Instructor",
          courses: 22,
          rating: 4.5,
          students: 3000,
        },
        {
          name: "Amy Joshua",
          avatar: require("@/assets/images/instructor3.png"),
          role: "A.I. Instructor",
          courses: 22,
          rating: 4.5,
          students: 3000,
        },
        {
          name: "Sonna Lin Chen",
          avatar: require("@/assets/images/instructor4.png"),
          role: "VR/AR Instructor",
          courses: 22,
          rating: 4.5,
          students: 3000,
        },
      ],
    };
  },
};
</script>

<style scoped>
.instructors {
  padding: 60px 0;
  text-align: center;
  max-width: 1140px;
  margin: 0 auto;
}

.instructors-label {
  border-radius: 4px;
  background: rgba(255, 76, 0, 0.15);
  color: #ff4c00;
  display: inline-block;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 25px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.instructors > p {
  max-width: 705px;
  margin: auto;
}

span {
  display: block;
}

.instructor-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.5rem;
  margin: 55px auto 0 auto;
}

.instructor-box {
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.33);
  border: none;
  border-radius: 8px;
  background: white;
  padding: 20px 15px;
  text-align: center;
}

.instructor-box > img {
  border-radius: 8px 8px 0 0;
}

.instructor-info {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.instructor-info > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instructor-image {
  margin-bottom: 20px;
}

.instructor-name {
  font-weight: 600;
  font-size: 18px;
}

.instructor-rating,
.instructor-info > div {
  font-size: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 480px) {
  .instructor-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
}

@media only screen and (max-width: 840px) {
  .instructor-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 2rem;
    margin: 55px auto 0 auto;
  }
}

@media only screen and (max-width: 1050px) {
  .instructor-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 2rem;
    margin: 55px auto 0 auto;
  }
}
</style>