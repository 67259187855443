<template>
  <!-- Foot -->
  <footer>
    <section class="footer-grid">
      <div class="footer-nav footer-nav__one">
        <img
          class="footer-logo"
          src="@/assets/images/logo/logo.png"
          alt="company logo"
        />
        <div>
          <p class="footer-about">
            We are a European-African start-up aiming to empower women through
            emerging technologies
          </p>
          <div class="footer-social">
            <img src="@/assets/icons/email.svg" alt="" /><span
              >team@utcolab.com</span
            >
          </div>
          <!-- <div class="footer-divider"></div> -->
          <!-- <div class="footer-social telephone">
            <img src="@/assets/icons/telephone.svg" alt="" />
            <div>
              <span>+372-5650-6076</span>
              <span>+372-5599-3493</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="footer-nav footer-nav__two">
        <div>
          <span class="footer-header">Company</span>
          <div class="footer-links">
            <ul>
              <li>About</li>
              <li>Our Programs</li>
              <li>Our Partners</li>
              <li>Our Team</li>
              <li>Terms of Service</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-nav footer-nav__three">
        <div>
          <span class="footer-header">Community</span>
          <div class="footer-links">
            <ul>
              <li>Join our community</li>
              <li>Join as a mentor</li>
              <li>Hire Talent</li>
              <li>Contact us</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-nav footer-nav__four">
        <div>
          <span class="footer-header">Subscribe to Newsletter</span>
          <p>
            Enter your email address for our mailing list to keep yourself
            updated
          </p>
          <div class="subscribe-wrapper">
            <input type="email" placeholder="Enter your Email address" />
            <button><img src="@/assets/icons/subscribe.svg" alt="" /></button>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>


<script>
export default {
  data() {
    return {
      host: "https://utcolab.org",
    };
  },
};
</script>

<style scoped>
/* The footer section */
footer {
  padding: 2em 0px;
  margin-top: 5em;
  background-color: #ffffff;
  box-shadow: 2px 0px 4px rgba(60, 18, 0, 0.15);
  /* color: rgba(60, 18, 0, 1) !im; */
}

.footer-nav span {
  display: block;
}

img.footer-logo {
  width: 97px;
  margin-bottom: 20px;
}

.footer-nav__one > div {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.footer-about,
.footer-social {
  font-size: 12px;
}

.footer-social {
  display: flex;
}

.footer-header {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}

.footer-social img {
  margin-right: 20px;
  width: 20px;
}

.footer-divider {
  height: 1px;
  width: 100%;
  background: rgba(60, 18, 0, 1);
}

.telephone {
  align-items: flex-start;
}

.telephone > div {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 80px;
}

.footer-nav {
  padding: 20px 0;
  width: 100%;
  color: hsl(15, 7%, 11%);
}

.footer-links {
  font-size: 14px;
  font-weight: 500;
}

.subscribe-wrapper {
  display: flex;
  border-radius: 4px;
  backdrop-filter: blur(22px);
  background: #f5f5f5;
}

.footer-nav__four p {
  font-size: 14px;
  margin-bottom: 20px;
}

.subscribe-wrapper input {
  width: 80%;
  font-size: 12px;
  border: none;
  border-radius: 4px 0 0 4px;
  padding: 8px 12px;
  background: #f5f5f5;
}

.subscribe-wrapper button {
  width: 20%;
  border: none;
  border-radius: 0 4px 4px 0;
  background: #ff4c00;
  padding: 10px;
  cursor: pointer;
}

.subscribe-wrapper button img {
  width: 24px;
}

.footer-nav ul {
  padding-left: 20px;
}

.footer-links ul li {
  margin-bottom: 12px;
}

@media (max-width: 800px) {
  footer {
    margin-top: 40px;
    padding-bottom: 60px;
  }
}

/* .footer-nav ul li span {
  color: rgb(63, 63, 63);
  font-size: 9px;
  padding: 3px;
} */

@media only screen and (max-width: 480px) {
  .footer-nav {
    padding: 5px 0;
  }

  .footer-header {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 840px) {
  .footer-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 5px;
  }
}

/* The footer section End */
</style>
