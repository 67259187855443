<template>
  <div class="programmes">
    <div class="programmes-label">Our Programmes</div>
    <h3>Browse Program Categories</h3>
    <p>Find your interests by browsing our online categories</p>
    <div class="programme-wrapper">
      <div
        v-for="(programme, index) in programmes"
        :key="index"
        class="programme-main-wrapper"
      >
        <div class="programme-box">
          <img class="programme-image" :src="programme.image" alt="" />
          <div class="programme-info">
            <div>
              <img class="programme-icon" :src="programme.icon" alt="" />
              <span class="programme-name">{{ programme.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      programmes: [
        {
          icon: require("@/assets/icons/ai.svg"),
          image: require("@/assets/images/ai.png"),
          name: "Artificial Intelligence",
        },
        {
          icon: require("@/assets/icons/ml.svg"),
          image: require("@/assets/images/ml.png"),
          name: "Machine Learning",
        },
        {
          icon: require("@/assets/icons/ar-vr.svg"),
          image: require("@/assets/images/vr-ar.png"),
          name: "VR & AR",
        },
        {
          icon: require("@/assets/icons/cc.svg"),
          image: require("@/assets/images/cc.png"),
          name: "Cloud Computing",
        },
      ],
    };
  },
};
</script>

<style scoped>
.programmes {
  padding: 60px 0;
  text-align: center;
  max-width: 1140px;
  margin: 0 auto;
}

.programmes-label {
  border-radius: 4px;
  background: rgba(255, 76, 0, 0.15);
  color: #ff4c00;
  display: inline-block;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 25px;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.programmes > p {
  max-width: 705px;
  margin: auto;
}

span {
  display: block;
}

.programme-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.5rem;
  margin: 55px auto 0 auto;
}

.programme-box {
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.33);
  border: none;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.programme-box > img {
  border-radius: 8px;
  width: 100%;
}

.programme-box .programme-info {
  background: rgba(60, 18, 0, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 8px;
}

.programme-info > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.programme-info img {
  width: 50px;
  text-align: center;
  margin-bottom: 5px;
}

.programme-name {
  font-weight: 600;
  font-size: 20px;
  color: white;
}

@media only screen and (max-width: 480px) {
  .programme-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
}
</style>