<template>
  <h2 class="enrolled-title">Settings</h2>
  <div class="enrolled-nav">
    <button
      @click="currentTab = 'enrolled-courses'"
      :class="{ activeTab: currentTab === 'enrolled-courses' }"
    >
      Enrolled Courses
    </button>
    <button
      @click="currentTab = 'active-courses'"
      :class="{ activeTab: currentTab === 'active-courses' }"
    >
      Active Courses
    </button>
    <button
      @click="currentTab = 'completed-courses'"
      :class="{ activeTab: currentTab === 'completed-courses' }"
    >
      Completed Courses
    </button>
  </div>
  <keep-alive class="keep-alive">
    <component :is="currentTab"></component>
  </keep-alive>
</template>

<script>
import EnrolledCourses from "./enrolledCourses/enrolledCourses.vue";
import ActiveCourses from "./enrolledCourses/activeCourses.vue";
import CompletedCourses from "./enrolledCourses/completedCourses.vue";
export default {
  components: {
    EnrolledCourses,
    ActiveCourses,
    CompletedCourses,
  },

  data() {
    return {
      currentTab: "enrolled-courses",
    };
  },
};
</script>

<style scoped>
.enrolled-title {
  font-size: 24px;
  font-weight: 600;
  color: rgba(34, 10, 0, 1);
  margin-bottom: 40px;
}

.activeTab {
  color: #001fc1;
  border-bottom: 1px solid #001fc1;
}

.enrolled-nav {
  border-bottom: 1px solid rgba(34, 10, 0, 0.2);
}

button {
  padding: 8px 15px;
  outline: none;
  border: none;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  margin-right: 25px;
  color: rgba(34, 10, 0, 0.7);

}

button:last-child {
  margin: 0;
}

.keep-alive {
  padding: 40px 0;
  margin: 0px;
  margin: 0px;
}
</style>
