<template>
  <section>
    <div class="course-div-heading">
      <h4>Courses only in emerging technologies</h4>
      <p>Start learning these emerging technologies</p>
    </div>
    <div class="course-div">
      <div class="card-container">
        <div
          class="shadow-1 ai"
          :style="{ backgroundImage: `url('${homeImages.aiBg}')` }"
        >
          <img :src="`${Icons.ai}`" alt="" />
        </div>
      </div>
      <div class="card-container">
        <div
          class="shadow-1 ml"
          :style="{ backgroundImage: `url('${homeImages.mlBg}')` }"
        >
          <img :src="`${Icons.ml}`" alt="" />
        </div>
      </div>
      <div class="card-container">
        <div
          class="shadow-1 vr"
          :style="{ backgroundImage: `url('${homeImages.vrBg}')` }"
        >
          <img :src="`${Icons.vr}`" alt="" />
        </div>
      </div>
      <div class="card-container">
        <div
          class="shadow-1 cloud"
          :style="{ backgroundImage: `url('${homeImages.cloudBg}')` }"
        >
          <img :src="`${Icons.cloud}`" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Icons from "../../assets/icons/home";
import homeImages from "../../assets/images/home";
export default {
  data: () => {
    return {
      Icons,
      homeImages,
    };
  },
};
</script>
<style scoped>
.course-div {
  margin-top: 60px;
  text-align: center;
  width: 100%;
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.course-div-heading {
  margin-top: 100px;
  display: grid;
  place-items: center;
}

.course-div-heading h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px !important;
  line-height: 45px;
  color: #220a00;
}

.course-div-heading p {
  font-size: 18px !important;
  font-weight: 400;
  margin: 20px 0;
}

.card-container {
  width: 250px;
  height: 250px;
}

.card-container .shadow-1 {
  border-radius: 11px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.card-container img {
  width: 80px;
  height: 100%;
}

.btn-outlined {
  border: 1px solid var(--primary);
  color: var(--primary);
  height: 45px;
  margin: 66px auto;
  border-radius: 5px;
  padding: 12px 35px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  display: grid;
  place-items: center;
}
</style>