<template>
  <div class="about-banner">
    <div class="banner-text">
      <h3>Welcome to UmbrellaTree CoLab</h3>
      <p class="text">
        We are a European-African start-up aiming to empower
        women through emerging
        technologies.We offer training, career advancement & entrepreneurship
        programmes
        for women in Africa interested in emerging
        technologies and in taking their career in
        tech to the next level! 
        You can start and finish any of the courses of your choice for free!
      </p>
      
      <button class="start-course">Start Course For Free</button>
    </div>
    <div class="banner-img">
      <img :src="`${aboutImages.welcome}`" alt="" />
    </div>
  </div>
</template>
<script>
import aboutImages from "../../assets/images/about";
export default {
  data:() => {
    return{
      aboutImages,
    }
  }
}
</script>
<style scoped>
  .about-banner{
    display: grid;
    grid-template-areas: "text image";
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap:56px;
    width: 100%;
    align-items: center;
    margin:100px auto 37px auto;
  }
  .about-banner .banner-img{
    width: 420px;
    grid-area: image;
  }
  .about-banner .banner-text h3{
    font-size: 24px;
    line-height: 35px;
  }
  .about-banner .banner-text .text{
    font-size: 16px;
    line-height: 25px;
    margin:40px 0;
  }
  .about-banner .banner-text .start-course{
    border-radius: 4px;
    border:none;
    width:270px;
    height:50px;
    background: var(--primary);
    color:#fff;
    font-size: 16px;
    cursor:pointer;
  }

  @media screen and (max-width:768px){
    .about-banner{
      grid-template-areas: "text"
      "image";
      gap:26px;
      width: 100%;
      margin:60px auto 27px auto;
    }
    .about-banner .banner-text h3{
      font-size: 18px;
      line-height: 30px;
      font-weight: 800;
    }
    .about-banner .banner-text .text{
      font-size: 14px;
      line-height: 22px;
      margin:20px 0;
    }
    .about-banner .banner-img{
      width: 90%;
      grid-area: image;
      margin:0 auto;
    }
  }
</style>