<template>
  <section class="course">
    <h3>Upcoming Courses</h3>
    <div class="divider"></div>
    <div class="course-wrapper">
      <div
        v-for="(course, index) in courses"
        :key="index"
        class="course-main-wrapper"
      >
        <div class="course-box">
          <img :src="course.image" alt="" />
          <div class="text-content">
            <div class="meta-content">
              <div class="course-instructor">
                <img :src="course.avatar" alt="" /><span>{{
                  course.instructor
                }}</span>
              </div>
              <div class="course-tag">
                {{ course.tag }}
              </div>
            </div>
            <span class="course-title">{{ course.title }}</span>
            <!-- <div class="meta-content">
              <div class="course-duration">
                <img src="@/assets/icons/duration.svg" alt="" /><span>{{
                  course.duration
                }}</span>
              </div>
              <div class="course-lectures">{{ course.lectures }} lectures</div>
            </div>
            <div class="meta-content">
              <div class="course-rating">4.5</div>
              <div class="course-price">
                <span class="old-price">{{ course.newPrice }}</span>
                <span class="new-price">{{ course.oldPrice }}</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      courses: [
        {
          title: "AI Ethics",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course.png"),
        },
        {
          title: "Risks from AI",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course2.png"),
        },
        {
          title: "AI Governance",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course3.png"),
        },
        {
          title: "AI Regulation in African Union and European Union",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course4.png"),
        },
        {
          title: "Data Science and Machine Learning with Python- Beginners",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course5.png"),
        },
        {
          title: "Deep Learning",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course6.png"),
        },
        {
          title: "Neural Networks",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course7.png"),
        },
        {
          title: "Reinforcement Learning",
          instructor: "Maha Jouini",
          avatar: require("@/assets/images/course-avatar.png"),
          tag: "Science",
          image: require("@/assets/images/course8.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.course {
  padding: 60px 0;
  text-align: center;
  max-width: 1140px;
  margin: 0 auto;
}

h3 {
  font-size: 28px;
  font-weight: bold;
}

span {
  display: block;
}

.divider {
  background: #ff4c00;
  height: 8px;
  width: 125px;
  border-radius: 14px;
  display: inline-block;
}

.course-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  row-gap: 3.5rem;
  margin: 55px auto 0 auto;
}

.course-box {
  text-align: left;
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.33);
  border: none;
  border-radius: 8px;
  background: white;
  min-height: 300px;
}

.course-box > img {
  border-radius: 8px 8px 0 0;
}

.text-content {
  padding: 15px 15px 15px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.course-instructor,
.course-duration {
  display: flex;
  align-items: center;
}

.meta-content,
.likes-comments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.meta-content {
  flex-wrap: wrap;
}

.meta-content img {
  margin-right: 5px;
}

.course-instructor img {
  width: 45px;
}

.course-duration img {
  width: 15px;
}

.course-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
}

.course-tag {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
  color: #ff4c00;
  background: rgba(255, 76, 0, 0.2);
}

.new-price {
  color: #ff4c00;
  font-weight: 600;
  font-size: 18;
}

.old-price {
  color: rgba(34, 10, 0, 0.8);
  text-decoration: line-through;
}

.old-price,
.course-instructor {
  font-size: 12px;
}

.course-duration,
.course-lectures {
  font-size: 12px;
  font-weight: 500;
}

.view-more {
  margin-top: 40px;
  border: #ff4c00 1px solid;
  border-radius: 5px;
  color: #ff4c00;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 35px;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .divider {
    display: none;
  }

  .course-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
}
</style>