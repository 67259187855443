<template>
  <section class="testimonials">
    <h3>What Users Say About Us</h3>
    <div class="divider"></div>
    <div class="testimony">
      <div class="testimonial__top-row">
        <img src="@/assets/images/Ellipse-300.svg" alt="" />
      </div>
      <div class="testimonial__mid-row">
        <img src="@/assets/images/Ellipse-302.svg" alt="" />
        <div class="testimony-wrapper">
          <span class="testifier-text">{{ testifier.text }}</span>
          <span class="testifier-name">{{ testifier.name }}</span>
          <span class="testifier-role">{{ testifier.role }}</span>
        </div>
        <img src="@/assets/images/Ellipse-307.svg" alt="" />
      </div>
      <div class="testimonial__bottom-row">
        <img src="@/assets/images/Ellipse-303.svg" alt="" />
        <img src="@/assets/images/Ellipse-304.svg" alt="" />
        <img src="@/assets/images/Ellipse-305.svg" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      testifier: {
        text: '"UmbrellaTree Colab has given me the opportunity to learn some technologies that I can never imagine learning. Also a huge thanks to my mentor for her time and patience.  I’ll forever be grateful. I recommend UmbrellaTree Colab to anyone that wants to learn futuristic technologies."',
        name: "Jessica Williams",
        role: "Cloud engineer",
      },
    };
  },
};
</script>

<style scoped>
.testimonials {
  padding: 60px 0;
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
}

h3 {
  font-size: 28px;
  font-weight: bold;
}

span {
  display: block;
}

.divider {
  background: #ff4c00;
  height: 8px;
  width: 125px;
  border-radius: 14px;
  display: inline-block;
}

.testimony {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin: 55px auto 0 auto;
}

.testimonial__top-row img {
  width: 150px;
}

.testimonial__mid-row img,
.testimonial__bottom-row img {
  width: 100px;
}

.testimonial__mid-row {
  display: flex;
  justify-content: space-between;
  column-gap: 25px;
  align-items: flex-start;
}

.testimonial__bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.testimony-wrapper {
  text-align: center;
  background: white;
  margin: 0 auto;
  border-radius: 8px;
  max-width: 600px;
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.35);
  padding: 50px 20px 20px 20px;
}

.testifier-text {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.testifier-name {
  color: #ff4c00;
  font-weight: 600;
  line-height: 1.5;
}

.testifier-role {
  font-weight: 600;
  font-size: 14;
}

@media only screen and (max-width: 480px) {
  .divider {
    display: none;
  }

  .testimonial__top-row,
  .testimonial__bottom-row,
  .testimonial__mid-row img {
    display: none;
  }

  .testimony-wrapper {
    padding: 20px;
  }
}
</style>