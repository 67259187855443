<template>
  <div class="course-div-heading">
    <button class="program">Our Programmes</button>
    <h4>Browse Program Categories</h4>
    <p>Find your interests by browsing our online categories</p>
  </div>
  <div class="course-div">
    <div class="card-container">
      <div class="shadow-1 ai" :style="{ backgroundImage: `url('${homeImages.aiBg}')` }">
        <img :src="`${Icons.ai}`" alt="" />
      </div>
    </div>
    <div class="card-container">
      <div class="shadow-1 ml" :style="{ backgroundImage: `url('${homeImages.mlBg}')` }">
        <img :src="`${Icons.ml}`" alt="" />
      </div>
    </div>
    <div class="card-container">
      <div class="shadow-1 vr" :style="{ backgroundImage: `url('${homeImages.vrBg}')` }">
        <img :src="`${Icons.vr}`" alt="" />
      </div>
    </div>
    <div class="card-container">
      <div class="shadow-1 cloud" :style="{ backgroundImage: `url('${homeImages.cloudBg}')` }">
        <img :src="`${Icons.cloud}`" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import Icons from "../../assets/icons/home";
import homeImages from "../../assets/images/home";
export default {
  data:() => {
    return{
      Icons,
      homeImages,
    }
  },
}
</script>
<style scoped>
  .course-div{
    margin-top: 60px;
    text-align: center;
    width: 100%;
    display: grid;
    row-gap: 40px;
    place-items: center;
    grid-template-columns: repeat(auto-fit, minmax(240px,1fr));
  }
  
  .course-div-heading{
    margin-top: 100px;
    display: grid;
    place-items: center;
  }
  .course-div-heading .program{
    color:var(--primary);
    background: #FDE2D7;
    border:none;
    border-radius: 4px;
    width:160px;
    height:50px;
    cursor:pointer;
    font-size: 16px;
    font-weight: 700;
  }
  .course-div-heading h4{
    font-style: normal;
    font-weight: bold;
    font-size: 28px !important;
    line-height: 45px;
    color: #220A00;
  }
  
  .course-div-heading  p{
    font-size: 18px !important;
    font-weight: 400;
    margin:10px 0 20px 0;
  }
  
  .card-container{
    width: 230px;
    height: 250px;
    margin: 0 5px;
  }
  
  .card-container .shadow-1{
    border-radius: 11px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  
  .card-container img{
    width: 80px;
    height: 100%;
  }
</style>