<template>
  <main>
    <img src="@/assets/images/login-poster.png" alt="" class="poster" />
    <section>
      <div class="form-container">
        <div class="form-header">
          <img src="@/assets/images/logo/logo.png" alt="" class="logo" />
          <div class="intro-text">
            <h1>Welcome back</h1>
            <p>Login with your details to continue</p>
          </div>
        </div>
        <div class="social-media-icons">
          <img src="@/assets/icons/form/gmail.svg" alt="" />
          <img src="@/assets/icons/form/facebook.svg" alt="" />
          <img src="@/assets/icons/twitter.svg" alt="" />
        </div>
        <div class="or">
          <div class="divider"></div>
          <span>or login with email</span>
          <div class="divider"></div>
        </div>
        <form>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              placeholder="hello@gmail.com"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="at least 8 characters"
            />
            <img src="@/assets/icons/form/closed-eye.svg" alt="" />
          </div>
          <a href=""><h4>Forgot Passowrd?</h4></a>
          <div class="form-group">
            <button type="submit" class="button-primary">Login</button>
          </div>
          <div class="form-group">
            <p>New to UmbrellaTree Colab? <a href="/register">Sign up</a></p>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
export default {};
</script>

<style scoped>
main {
  margin-top: 0px;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;
}
.poster {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

section {
  width: 100%;
}
.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 30px;
  margin-top: 48px;
}

.form-header {
  text-align: center;
  place-items: center;
  display: grid;
  gap: 20px;
}

.intro-text p {
  color: #1a194acc;
  font-weight: bold;
}

.intro-text h1 {
  margin-bottom: 10px;
  color: #1a194a;
}

.logo {
  width: 75px;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.social-media-icons img {
  height: 45px;
  width: 45px;
}

.or {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  margin: 20px 0;
  font-size: 16px;
  gap: 5px;
  color: rgba(26, 25, 74, 0.8);
  width: 100%;
  text-align: center;
}

.divider {
  height: 1px;
  width: 100%;
  border: 1px solid rgba(26, 25, 74, 0.2);
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

label {
  color: #220a00;
  margin-bottom: 10px;
}
.form-control {
  border: 1px solid #220a004d;
  color: #220a00;
  padding: 12px 20px;
  border-radius: var(--input-border-radius);
  height: 45px;
}

.form-group {
  position: relative;
  width: 100%;
}

.form-group img {
  height: 15px;
  width: 16px;
  position: absolute;
  right: 20px;
  bottom: 14px;
}

form h4 {
  text-align: right;
  font-size: 14px;
}

.form-group button {
  width: 100%;
}

.form-group p {
  text-align: center;
  color: #220a00;
}

.form-group p a {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .poster {
    display: none;
  }
}
</style>