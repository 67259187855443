<template>
  <section>
    <h3 class="title-h1">What we offer?</h3>
    <div class="offer">
      <div class="shadow-1">
        <img :src="`${homeImages.accesImg}`" alt="" />
        <div class="card-item">
          <h5>Unlimited Free Access</h5>
          <p>Access courses taught by industry professionals</p>
        </div>
      </div>
      <div class="shadow-1">
        <img :src="`${homeImages.cert}`" alt="" />
        <div class="card-item">
          <h5>Certificate of Completion</h5>
          <p>
            Stand out by earning a certificate to add to your LinkedIn profile
          </p>
        </div>
      </div>
      <div class="shadow-1">
        <img :src="`${homeImages.code}`" alt="" />
        <div class="card-item">
          <h5>Fully online</h5>
          <p>Learn when and wherever you want to</p>
        </div>
      </div>
      <div class="card shadow-1">
        <img :src="`${homeImages.interact}`" alt="" />
        <div class="card-item">
          <h5>Interactive Learning</h5>
          <p>Turn learning into practice with quizzes, tasks and exercises</p>
        </div>
      </div>
      <div class="shadow-1">
        <img :src="`${homeImages.personalized}`" alt="" />
        <div class="card-item">
          <h5>Personalized for You</h5>
          <p>
            Community-based learning together with women and an option to get a
            personalized approach exactly to your needs
          </p>
        </div>
      </div>
      <div class="shadow-1">
        <img :src="`${homeImages.job}`" alt="" />
        <div class="card-item">
          <h5>Jobs and More</h5>
          <p>
            Gain access to job and internship opportunities through our platform
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import homeImages from "../../assets/images/home";
export default {
  data: () => {
    return {
      homeImages,
    };
  },
};
</script>
<style scoped>
.title-h1 {
  width: 300px;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 10px;
  margin: 100px auto 0;
  line-height: 2em;
}

.title-h1::after {
  content: "";
  display: grid;
  height: 8px;
  margin-top: 20px;
  border-radius: 50px;
  width: 125px;

  margin: 0 auto;
  background-color: var(--primary);
  place-items: center;
}
.offer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 45px;
  margin: 60px auto;
  padding: 10px;
}
.offer .shadow-1 img {
  width: 50px;
  height: 50px;
  margin: 20px 0 25px 20px;
}
.offer .shadow-1 .card-item {
  margin: 0 20px 25px 20px;
}
.offer .shadow-1 .card-item h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}
.offer .shadow-1 .card-item p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
}
</style>