import hero from "./hero-image.png";
import image1 from "./img-1.png";
import image2 from "./img-2.png";
import arrow from "./arrow.png";
import arrowFill from "./arrow-fill.png";
import search from "./search.png";
import calendar from "./calendar.png";
import badge from "./badge.png";
import aiBg from "./ai-bg.png";
import cloudBg from "./cloud-bg.png";
import vrBg from "./vr-bg.png";
import mlBg from "./ml-bg.png";
import cert from "./certificate.png";
import code from "./code.png";
import interact from "./interactive.png";
import job from "./job.png";
import personalized from "./personalized.png";
import accesImg from "./access.png";
import indexHero from './index-hero-image.png'
import indexWelcome from './index-welcome-image.png'
import indexWelcome2 from './index-welcome-image2.png'



const homeImages ={
  hero,
  image1,
  image2,
  arrow,
  arrowFill,
  search,
  calendar,
  badge,
  aiBg,
  cloudBg,
  vrBg,
  mlBg,
  cert,
  code,
  interact,
  job,
  accesImg,
  personalized,
  indexHero,
  indexWelcome,
  indexWelcome2
}
export default homeImages;