// layouts
import main from '@/layout/main.vue';
import empty from '@/layout/empty.vue';
import dashboard from '@/layout/dashboard.vue';

// pages
import Home from '@/pages/home.vue';
import About from '@/pages/about.vue';
import Login from '@/pages/user authentication/login.vue';
import Register from '@/pages/user authentication/register.vue';

// dashboard screens
import DashboardHome from '@/pages/dashboard/home.vue';
import ProfilePage from '@/pages/dashboard/profilePage.vue';
import Settings from '@/pages/dashboard/settings.vue';
import EnrolledCourses from '@/pages/dashboard/enrolledCourses.vue';
import Wishlist from '@/pages/dashboard/wishlist.vue';
import QuizAttempts from '@/pages/dashboard/quizAttempts.vue';
import PurchaseHistory from '@/pages/dashboard/purchaseHistory.vue';

// errors
import error404 from '@/pages/errors/error404.vue';
import error500 from '@/pages/errors/error500.vue';

const routes = [
  {
    path: '/',
    component: main,
    props: true,
    children: [
      // home page
      { path: '', component: Home, meta: { entryPoint: true } },
      { path: 'home', component: Home },

      // about page
      { name: 'about', path: 'about', component: About },
    ],
  },

  {
    path: '/dashboard',
    component: dashboard,
    props: true,
    children: [
      { name: 'dashboardHome', path: '/', component: DashboardHome },
      {
        name: 'enrolledCourses',
        path: '/enrolled-courses',
        component: EnrolledCourses,
      },
      { name: 'wishlist', path: '/wishlist', component: Wishlist },
      { name: 'quizAttempts', path: '/quiz-attempts', component: QuizAttempts },
      {
        name: 'purchaseHistory',
        path: '/purchase-history',
        component: PurchaseHistory,
      },
      { name: 'profilePage', path: '/profile', component: ProfilePage },
      { name: 'settings', path: '/settings', component: Settings },
    ],
  },

  {
    path: '/',
    props: true,
    component: empty,
    children: [
      { name: 'error404', path: 'error404', component: error404 },
      { name: 'error500', path: 'error500', component: error500 },
    ],
  },

  {
    path: '/:catchAll(.*)*',
    component: error404,
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  }
];

export default routes;
