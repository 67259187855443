<template>
  <div>
    <div class="about-banner">
      <div class="banner-text">
        <h3>Our Mission</h3>
        <p class="text">
          UmbrellaTree CoLab’s mission is to democratize education through the offering
          of world-class higher education opportunities that are accessible, flexible, and
          economical.
          Virtually anyone in the planet with internet connection and a commitment to self
          empowerment through learning can come to UmbrellaTree CoLab, master a suite
          of job-ready skills, and purse rewarding employment.
        </p>
      </div>
      <div class="banner-img">
        <img :src="`${aboutImages.welcome}`" alt="" />
      </div>
    </div>
    <div class="stat">
      <div class="student stat-div">
        <img :src="`${aboutIcons.student}`" alt="">
        <h1>300+</h1>
        <p>Students</p>
      </div>
      <div class="course stat-div">
        <img :src="`${aboutIcons.course}`" alt="">
        <h1>10+</h1>
        <p>Courses</p>
      </div>
      <div class="instructor stat-div">
        <img :src="`${aboutIcons.instructor}`" alt="">
        <h1>50+</h1>
        <p>Instructors</p>
      </div>
      <div class="satisfy stat-div">
        <img :src="`${aboutIcons.guarantee}`" alt="">
        <h1>100%</h1>
        <p>Satisfaction rate</p>
      </div>
      <div class="language stat-div">
        <img :src="`${aboutIcons.language}`" alt="">
        <h1>10+</h1>
        <p>Languages</p>
      </div>
    </div>
  </div>
</template>
<script>
import aboutImages from "../../assets/images/about";
import aboutIcons from "../../assets/icons/about";
export default {
  data:() => {
    return{
      aboutImages,
      aboutIcons,
    }
  }
}
</script>
<style scoped>
  .about-banner{
    display: grid;
    grid-template-areas: "image text";
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap:56px;
    width: 100%;
    align-items: center;
    margin:100px auto 37px auto;
  }
  .about-banner .banner-img{
    width: 420px;
    grid-area: image;
  }
  .about-banner .banner-text h3{
    font-size: 24px;
    line-height: 35px;
  }
  .about-banner .banner-text .text{
    font-size: 16px;
    line-height: 25px;
    margin:40px 0;
  }
  .stat{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
    gap:50px;
    place-items: center;
    margin:60px auto;
  }
  .stat .stat-div{
    text-align: center;
  }
  .stat .stat-div img{
    width:100px;
  }

  @media screen and (max-width:768px){
    .about-banner{
      grid-template-areas: "text"
      "image";
      gap:26px;
      width: 100%;
      margin:60px auto 27px auto;
    }
    .about-banner .banner-text h3{
      font-size: 18px;
      line-height: 30px;
      font-weight: 800;
    }
    .about-banner .banner-text .text{
      font-size: 14px;
      line-height: 22px;
      margin:20px 0;
    }
    .about-banner .banner-img{
      width: 90%;
      grid-area: image;
      margin:0 auto;
    }

    .stat{
      gap:30px;
      margin:60px auto;
    }
    .stat .stat-div{
      text-align: center;
    }
    .stat .stat-div img{
      width:100px;
    }
  }
</style>