import play from "./play-btn.svg";
import  language from "./language.svg";
import course from "./course.svg";
import guarantee from "./guarantee.svg";
import instructor from "./instructor.svg";
import student from "./student.svg";


const aboutIcons = {
  play,
  student,
  language,
  course,
  guarantee,
  instructor,
}
export default aboutIcons;