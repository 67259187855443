import hero from "./hero.png";
import welcome from "./welcome.png";
import cert from "./cert.png";
import content from "./content.png";
import empower from "./empower.png";
import pace from "./pace.png";
import personal from "./personal.png";
import expert from "./expert.png";


const aboutImages = {
  hero,
  welcome,
  cert,
  content,
  empower,
  pace,
  personal,
  expert,
  
}
export default aboutImages;