<template>
  <section>
    <no-course
      :img="
        require('@/assets/images/dashboard/enrolled-courses/enrolled-courses.png')
      "
      msg="You do not have an active course at the moment"
    />
    <!-- <course-list /> -->
  </section>
</template>

<script>
import NoCourse from "./components/noCourse.vue";
// import CourseList from "./components/courseList.vue";
export default {
  components: {
    NoCourse,
    // CourseList,
  },

  data() {
    return {};
  },
};
</script>