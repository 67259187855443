<template>
  <div class="teams">
    <div class="teams-label">Our Team</div>
    <h3>Meet Our Team</h3>
    <p>
      Our expert team is made up of creatives with technical knowledge,
      strategists who think outside the box, and developers who push
      innovations.
    </p>
    <div class="team-wrapper">
      <div
        v-for="(member, index) in team"
        :key="index"
        class="team-main-wrapper"
      >
        <div class="team-box">
          <img class="team-image" :src="member.avatar" alt="" />
          <div class="team-info">
            <span class="team-name">{{ member.name }}</span>
            <span class="team-role">{{ member.role }}</span>
            <div class="team-social">
              <a :href="member.twitter"
                ><img src="@/assets/icons/twitter.svg" alt=""
              /></a>
              <a :href="member.linkedIn"
                ><img src="@/assets/icons/linkedin-icon.svg" alt=""
              /></a>
              <a :href="member.instagram"
                ><img src="@/assets/icons/ig.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      team: [
        {
          name: "Tiina Rootamm",
          avatar: require("@/assets/images/team1.png"),
          role: "Co-Founder",
          twitter: "",
          linkedIn: "",
          instagram: "",
        },
        {
          name: "Jaanika Umalas",
          avatar: require("@/assets/images/team2.png"),
          role: "Co-Founder",
          twitter: "",
          linkedIn: "",
          instagram: "",
        },
        {
          name: "Marvelous Akporowho",
          avatar: require("@/assets/images/team3.png"),
          role: "Chief Technical Officer",
          twitter: "",
          linkedIn: "",
          instagram: "",
        },
        {
          name: "Francis Ogbonna",
          avatar: require("@/assets/images/team4.png"),
          role: "Software Engineer",
          twitter: "",
          linkedIn: "",
          instagram: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.teams {
  padding: 60px 0;
  text-align: center;
  max-width: 1140px;
  margin: 0 auto;
}

.teams-label {
  border-radius: 4px;
  background: rgba(255, 76, 0, 0.15);
  color: #ff4c00;
  display: inline-block;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 25px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.teams > p {
  max-width: 705px;
  margin: auto;
}

span {
  display: block;
}

.team-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.5rem;
  margin: 55px auto 0 auto;
}

.team-box {
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.33);
  border: none;
  border-radius: 8px;
  background: white;
  padding: 20px 15px;
  text-align: center;
}

.team-box > img {
  border-radius: 8px 8px 0 0;
}

.team-info {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.team-info > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-image {
  margin-bottom: 20px;
}

.team-name {
  font-weight: 600;
  font-size: 18px;
}

.team-social {
  margin-top: 10px;
  width: 70%;
  display: flex;
  align-self: center;
}

@media only screen and (max-width: 480px) {
  .team-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
}

@media only screen and (max-width: 840px) {
  .team-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 2rem;
  }

  .team-social {
    width: 60% !important;
    display: flex;
    align-self: center;
  }
}

@media only screen and (max-width: 1050px) {
  .team-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 2rem;
  }

  .team-social {
    width: 50%;
    display: flex;
    align-self: center;
  }
}
</style>