<template>
  <section class="blog">
    <h3>Blog</h3>
    <div class="divider"></div>
    <div class="blog-wrapper">
      <div
        v-for="(blog, index) in blogs"
        :key="index"
        class="blog-main-wrapper"
      >
        <div class="blog-box">
          <img :src="blog.image" alt="" />
          <div class="text-content">
            <div class="meta-content">
              <div class="published-time">
                Published on: {{ blog.publishedOn }}
              </div>
              <div class="likes-comments">
                <div class="likes">
                  <img src="@/assets/icons/likes.svg" alt="" />{{ blog.likes }}
                  Likes
                </div>
                <div class="comments">
                  <img src="@/assets/icons/comments.svg" alt="" />{{
                    blog.comments
                  }}
                  Comments
                </div>
              </div>
            </div>
            <span class="blog-heading">{{ blog.heading }}</span>
            <span class="blog-excerpts">{{ blog.excerpts }}</span>
              <div class="read-more">
                Read more
              </div>
          </div>
        </div>
      </div>
    </div>
    <button class="view-more">View more</button>
  </section>
</template>

<script>
export default {
  data() {
    return {
      blogs: [
        {
          heading:
            "Best Cloud Engineer Portfolios - Inspiring Case Studies and Examples",
          excerpts:
            "A long list of relevant skills, the tools you use, and how many years of experience you have is of no real benefit to your visitors, or to your presentation. It’s more important to outline your user-centered design process—how the problem was solved. Be explicit about your skills, the process you use, and the kinds of projects you specialize in, but be mindful...",
          image: require("@/assets/images/Rectangle-403.svg"),
          publishedOn: "19th July 2021",
          likes: 234,
          comments: 54,
        },
        {
          heading:
            "Best Cloud Engineer Portfolios - Inspiring Case Studies and Examples",
          excerpts:
            "A long list of relevant skills, the tools you use, and how many years of experience you have is of no real benefit to your visitors, or to your presentation. It’s more important to outline your user-centered design process—how the problem was solved. Be explicit about your skills, the process you use, and the kinds of projects you specialize in, but be mindful...",
          image: require("@/assets/images/Rectangle-403.svg"),
          publishedOn: "19th July 2021",
          likes: 234,
          comments: 54,
        },
      ],
    };
  },
};
</script>

<style scoped>
.blog {
  padding: 60px 0;
  text-align: center;
  max-width: 1140px;
  margin: 0 auto;
}

h3 {
  font-size: 28px;
  font-weight: bold;
}

span {
  display: block;
}

.divider {
  background: #ff4c00;
  height: 8px;
  width: 125px;
  border-radius: 14px;
  display: inline-block;
}

.blog-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.5rem;
  margin: 55px auto 0 auto;
}

.blog-box {
  text-align: left;
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.33);
  border: none;
  border-radius: 8px;
  background: white;
}

.blog-box > img {
  border-radius: 8px 8px 0 0;
}

.text-content {
  padding: 20px 20px 25px 20px;
}

.meta-content,
.likes-comments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.meta-content {
  flex-wrap: wrap;
}

.likes-comments {
  column-gap: 20px;
}

.likes,
.comments {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meta-content img {
  width: 22px;
  margin-right: 10px;
}

.blog-heading {
  font-weight: 500;
  font-size: 20px;
  margin: 30px 0;
}

.blog-excerpts {
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 1.7;
}

.published-time {
  color: #ff4c00;
}

.read-more {
  color: #ff4c00;
  font-weight: bold;
}

.view-more {
  margin-top: 40px;
  border: #ff4c00 1px solid;
  border-radius: 5px;
  color: #ff4c00;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 35px;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .blog-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }

  .divider {
    display: none;
  }
}
</style>